import * as React from "react";
import Layout from "./Layout";
import { API, graphqlOperation, Auth } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { Link } from "gatsby";
import { Box, Button } from "@material-ui/core";
import * as mutations from "../../graphql/mutations";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { GetPaymentQuery } from "../../API";
import { withAuthenticator } from "@aws-amplify/ui-react";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const PaymentSuccess = () => {
  // React.useEffect(() => {
  //   async function setSession() {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const sessionID = urlParams.get("session_id");
  //     const cognito = await Auth.currentUserInfo();
  //     if (sessionID && cognito) {
  //       const userID = cognito.username;
  //       const resPayment = (await API.graphql(
  //         graphqlOperation(queries.getPayment, { id: userID })
  //       )) as GraphQLResult<GetPaymentQuery>;
  //       console.log("resPayment", resPayment);
  //       try {
  //         if (resPayment?.data?.getPayment) {
  //           await API.graphql(
  //             graphqlOperation(mutations.updatePayment, {
  //               input: { id: userID, sessionID },
  //             })
  //           );
  //         } else {
  //           await API.graphql(
  //             graphqlOperation(mutations.createPayment, {
  //               input: { id: userID, sessionID, owner: userID },
  //             })
  //           );
  //         }
  //       } catch {
  //         alert("通信エラーが発生しました。");
  //       }
  //     }
  //   }
  //   setSession();
  // }, []);
  return (
    <Layout>
      <Box m={8}>
        <Alert severity="success">購入完了しました。</Alert>
        <div style={{ marginTop: 32 }}>
          <Button
            component={Link}
            to="/member/"
            color="primary"
            variant="contained"
          >
            戻る
          </Button>
        </div>
      </Box>
    </Layout>
  );
};

export default withAuthenticator(PaymentSuccess);
